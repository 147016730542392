import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MovieCard from './MovieCard';
import { API_KEY } from './constants';

const API_LIST_ID = '8234019';
const API_LIST_URL = `https://api.themoviedb.org/3/list/${API_LIST_ID}?api_key=${API_KEY}&language=es-ES`;

const App = () => {
  const [movies, setMovies] = useState([]);
  const [error, setError] = useState(false);

  const fetchMovies = async () => {
    try {
      const response = await axios.get(API_LIST_URL);
      setMovies(response.data.items);
    } catch (error) {
      console.error(error);
      setError(true);
    }
  }

  useEffect(() => {
    fetchMovies();
  }, []);

  return (
    <div>
      {error ? (
        <p>Ups... No se han podido cargar las películas</p>
      ) : (
        <div className='flex flex-wrap justify-items-stretch'>
          {movies.map((movie, index) => (
            <MovieCard
              key={movie.id}
              movieId={movie.id}
              posterPath={movie.poster_path}
              title={movie.title}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default App;