import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_KEY } from '../constants';

const MovieCard = (props) => {
    const [watchProviders, setWatchProviders] = useState([]);
    const [error, setError] = useState(false);

    const fetchWatchProviders = async (movieId) => {
        try {
            const API_WATCH_PROVIDERS_URL = `https://api.themoviedb.org/3/movie/${movieId}/watch/providers?api_key=${API_KEY}`;
            const response = await axios.get(API_WATCH_PROVIDERS_URL);
            const results = response.data.results;
            if (results && results.ES) {
                // Filter non interesting providers (TODO: use a whitelist of providers)
                const providers = results.ES.flatrate?.filter((provider) => { return provider.display_priority < 20});
                setWatchProviders(providers);
            }
        } catch (error) {
            console.error(error);
            setError(true);
        }
    }

    useEffect(() => {
        fetchWatchProviders(props.movieId);
    }, [props]);

    return (
        <div className={`relative w-1/2 md:w-1/4 lg:w-1/6 xl:w-1/8 overflow-hidden ${watchProviders?.length > 0 ? 'cursor-pointer' : 'grayscale hover:brightness-75'}`}>
            <img
                className={`h-full w-full object-cover object-center ${watchProviders?.length > 0 ? 'transition duration-200 ease-in-out transform hover:scale-105' : ''}`}
                src={`https://image.tmdb.org/t/p/w500/${props.posterPath}`}
                alt={props.title}
                data-id={props.movieId}
            />
            { !error && <div className="absolute grid gap-1 grid-cols-4 bottom-1 left-1 right-1">
                {watchProviders?.map((watchProvider, index) => {
                    return <img
                                key={watchProvider.provider_id}
                                alt={watchProvider.provider_name}
                                className="rounded-lg drop-shadow-sm transition duration-200 ease-in-out transform hover:scale-105"
                                src={`https://image.tmdb.org/t/p/original/${watchProvider.logo_path}`}
                            />
                })}
            </div> }
        </div>
    );
}

export default MovieCard;
